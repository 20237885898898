import React from "react";
import {GatsbyImage} from "gatsby-plugin-image";
import {Link} from "gatsby";

import {IHelpSection} from "../help/help-section.interface";
import TextFormatter from "../../../utils/TextFormatter";
import Help from "../help/Help";
import {GatsbyImageCustomType} from "../../../types/gatsby-image.type";

export interface IFeatureCard {
  title: string,
  icon: GatsbyImageCustomType,
  url: string,
}

export interface IFeatureNavigationSubpage {
  title: string,
  description: {
    data: {
      description: string,
    },
  },
  help: IHelpSection,
  features: IFeatureCard[]
}

interface IFeatureNavigationSubpageProps {
  data: IFeatureNavigationSubpage
}

const FeatureNavigationSubpage = ({data}: IFeatureNavigationSubpageProps) => {
  const {title, description, help, features} = data;
  const formattedDescription = TextFormatter.concatOrphans(description.data.description) || description.data.description;

  return (
    <section>
      <div className={'flex flex-col p-10 px-5 sm:px-10 xl:px-0 relative bg-neutrals-90 items-center overflow-visible pt-24'}>
        <div className={'flex flex-col justify-center items-center text-center max-w-[768px]'}>
          <h1 className={'font-head text-4xl xl:text-5xl text-black mb-4'}>{title}</h1>
          <div className={'text-neutrals-40 text-lg mb-12'} dangerouslySetInnerHTML={{__html: formattedDescription}}/>
        </div>
        <div className={'grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-5 z-10 shrink-0'}>
          {features.map((feature, index) => {
            return (
              <div key={index} className={'flex flex-col p-7 border border-neutrals-60 rounded bg-white w-[250px] xs:w-[300px]'}>
                <GatsbyImage loading={'eager'} className={'shrink-0 mb-6'} alt={feature.icon.alternativeText} image={feature.icon.localFile.childImageSharp.gatsbyImageData} />
                <p className={'font-head text-xl xs:text-2xl mb-14 break-all sm:break-normal'}>{feature.title}</p>
                <Link className={'mtr-btn-large mtr-btn-[secondary] h-12 ml-auto mt-auto'} to={feature.url}>
                  <span>SPRAWDŹ</span>
                  <span className={'ml-2 mtr-icon text-white text-xl'}>arrow_forward</span>
                </Link>
              </div>
            )
          })}
        </div>
        <div className={'bg-white absolute bottom-0 left-0 w-full h-32 z-0'} />
      </div>
      <Help helpSection={help} />
    </section>
  )
}

export default FeatureNavigationSubpage;
