import React from "react";
import {graphql, HeadProps, PageProps} from "gatsby";

import Layout from "../components/Layout";
import {IHelpSection} from "../components/templates/help/help-section.interface";
import FeatureNavigationSubpage, {
  IFeatureCard
} from "../components/templates/feature-navigation-subpage/FeatureNavigationSubpage";
import {SiteMetadata} from "../types/site-metadata.type";
import {SiteMetadataConfigProps} from "../types/metadata-config-props.type";
import {PageContextType} from "../types/page-context.type";
import StructuredData from '../assets/structured-data';

type DeviceProps = {
  strapiDevice: {
    title: string,
    siteMetadata: SiteMetadata,
    description: {
      data: {
        description: string,
      },
    },
    help: IHelpSection,
    features: IFeatureCard[],
  }
} & SiteMetadataConfigProps

const Device = ({data: { strapiDevice }, pageContext}: PageProps<DeviceProps, PageContextType>) => {
  return (
    <Layout crumbs={pageContext.breadcrumb.crumbs} crumbLabel={strapiDevice.siteMetadata.crumbLabel}>
      <FeatureNavigationSubpage data={strapiDevice} />
    </Layout>
  )
}

export default Device;

export const Head = (props: HeadProps<DeviceProps>) => {
  const slug = props.location.pathname;
  const siteUrl = props.data.site.siteMetadata.siteUrl;
  const {title, description, crumbLabel} = props.data.strapiDevice.siteMetadata;
  return (
    <>
      <title>{title}</title>
      <meta name="description" content={description}/>
      <meta name="robots" content="index, follow"/>
      <meta name="theme-color" content="#24B25B"/>
      {StructuredData([
        {
          name: crumbLabel,
          item: siteUrl + slug
        }
      ])}
    </>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
    strapiDevice {
      siteMetadata {
        title
        description
        crumbLabel
      }
      title
      description {
        data {
          description
        }
      }
      help {
        title
        number
        email
        description
      }
      features {
        title
        url
        icon {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`
